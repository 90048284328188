<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="alertCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary"> {{ alertCount }} </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <div class="scrollable-container media-list scroll-area" tagname="li">
      <!-- System Notification Toggler -->
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">Stock Alerts</h6>
        <!-- <b-form-checkbox :checked="true" switch /> -->
      </div>

      <!-- System Notifications -->
      <b-link v-for="notification in notification.itemList" :key="notification.id">
        <b-media>
          <template #aside>
            <b-avatar size="32" variant="danger">
              <feather-icon icon="AlertCircleIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.itemName }}
            </span>
          </p>
          <small class="notification-text text-danger fw-bold"
            >Current Stock : {{ notification.currentStock }}</small
          >
        </b-media>
      </b-link>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,

    ToastificationContent,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      alertCount: 0,
      alertCountSystem: [],
    };
  },

  computed: {
    ...mapGetters("notificationModule", {
      notification: "notification",
      loading: "loading",
    }),
  },

  methods: {
    ...mapActions("notificationModule", ["getNotificationsAction"]),
    // showToast(variant) {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: "Low Stock Alert",
    //       icon: "AlertCircleIcon",
    //       text: `Current Stock of ${variant.itemName} is ${variant.currentStock}`,
    //       variant: "danger",
    //     },
    //   });
    // },
  },
  async mounted() {
    await this.getNotificationsAction()
      .then(() => {
        this.alertCount = this.notification.totalCount;
        this.alertCountSystem = this.notification.itemList;
        // this.alertCountSystem.forEach((element) => {
        //   this.showToast(element);
        // });
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>

<style>
.scrollable-container {
  overflow: auto;
}


</style>
