export default [
  {
    header: 'Pages & Modules',
  },
  {
    title: 'Marketing',

    icon: 'CalendarIcon',
    children: [
      {
        //Calender
        title: 'Marketing Plan',
        route: 'marketing-plan-calender',
      },
      {
        title: 'Marketing Content',
        route: 'marketing-blog-list',
      },
    
      // {
      //   title: 'Marketing Chat',
      //   route: 'marketing-chat',
      // },
    ],
  },

  {
    title: 'Sales',

    icon: 'ShoppingCartIcon',
    children: [
     
      // {
      //   title: 'Opportunity',
      //   route: 'opportunity-list',
      // },
      // {
      //   title: 'Service Request',
      //   route: 'request-list',
      // },
      {
        title: 'Leads',
        route: 'leads-list',
      },
      {
        title: 'Customers',
        route: { name: 'customer-list' },
      },
      // {
      //   title: 'Complaints',
      //   route: { name: 'complaint-list' },
      // },
      // {
      //   title: 'Pipeline',
      //   route: { name: 'pipeline' },
      // },
      {
        title: 'Quotations',
        route: { name: 'quotation-list' },
      },
      {
        title: 'Proforma',
        route:  { name: 'proforma-list' },
      },
      {
        title: 'Invoices',
        route: 'invoice-list',
      },

    ],
  },
  {
    title: 'Inventory',

    icon: 'PackageIcon',
    children: [
      {
        title: 'Categories',
        route: 'itemcategories',
      },
      {
        title: 'Products',
        route: 'product-list',
      },
      {
        title: 'Raw Materials',
        route: 'rawmaterials-list',
      },
      {
        title: 'Labour',
        route: 'labour-list',
      },
      {
        title: 'Suppliers',
        route: 'supplier-list',
      },
      {
        title: 'Warehouses',
        route: 'warehouse-list',
      },
      {
        title: 'Purchase Orders',
        route: 'purchaseorders-list',
      },
      {
        title: 'Transactions',
        route: 'inventory-transaction-list',
      }
    ],
  },
  {
    title: 'Services',

    icon: 'PackageIcon',
    children: [
      // {
      //   title: 'Templates',
      //   route: 'templates',
      // },
      {
        title: 'Service Template',
        route: 'templates-list',
      },
      {
        title: 'Service Request',
        route: 'request-list',
      },
    ],
  },
  {
    title: 'Equipment',

    icon: 'ToolIcon',
    route: 'equipment-list',
  },
  {
    title: 'Production',

    icon: 'TruckIcon',
    children: [
      {
        title: 'Job Cards',
        route: 'jobcard-list',
      },
   
      {
        title: 'Planning & Monitoring',
        route: 'monitoring-plan-calender',
      },
    ],
  },

  {
    title: 'Reports',

    icon: 'BarChartIcon',
    children: [
      {
        title: 'Sales Reports',
        route: 'reports-sales',
        
      },
      {
        title: 'Inventory Reports',
        route: 'reports-inventory',
      },
      {
        title: 'Production Reports',
        route: 'reports-production',
      },

      {
        title: 'Marketing Reports',
        route:'reports-marketing',
      },
    ],
  },
  {
    title: 'Users',

    icon: 'UserXIcon',
    children: [
      {
        title: 'Users',
        route: 'role-users',
      },
      {
        title: 'Roles',
        route: 'role-list',
      },
      {
        title: 'Company',
        route: 'role-tenant',
      },
    ],
  },
  {
    title: 'Support',

    icon: 'HeadphonesIcon',
    route: 'support-dashboard',
  },
  {
    title: 'Settings',

    icon: 'SettingsIcon',
    route: 'pages-account-setting',
  },
]
