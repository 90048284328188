<template>
  <div id="my-container">
    <b-nav-item v-b-modal.modal-default class="mr-1 cursor-pointer">
      <svg
        id="Layer_1"
        fill="currentColor"
        height="21"
        viewBox="0 0 24 24"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
      >
        <path
          d="m18 24h-12a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h12a5.006 5.006 0 0 1 5 5v14a5.006 5.006 0 0 1 -5 5zm-12-22a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-14a3 3 0 0 0 -3-3zm10 8h-8a3 3 0 0 1 0-6h8a3 3 0 0 1 0 6zm-8-4a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2zm-2 7a1 1 0 1 0 1 1 1 1 0 0 0 -1-1zm4 0a1 1 0 1 0 1 1 1 1 0 0 0 -1-1zm4 0a1 1 0 1 0 1 1 1 1 0 0 0 -1-1zm-8 4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1zm4 0a1 1 0 1 0 1 1 1 1 0 0 0 -1-1zm8-4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1zm1 5a1 1 0 0 0 -1-1h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 1-1z"
        />
      </svg>
    </b-nav-item>

    <b-modal id="modal-default" hide-footer centered>
      <div>
        <!-- Calculator Result -->
        <div
          id="calc-display"
          class="rounded m-1 px-1 py-2 border text-right lead font-weight-bold"
        >
          {{ calculatorValue || 0 }}
        </div>

        <!-- Calculator buttons -->
        <div class="row no-gutters">
          <div class="col-3" v-for="n in calculatorElements" :key="n">
            <div
              class="lead text-center m-1 py-1 border rounded hover-class"
              :class="{ 'bg-success': ['C', '*', '/', '-', '+', '%', '='].includes(n) }"
              @click="action(n)"
            >
              {{ n }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BPopover,
  BButton,
  BFormGroup,
  BFormInput,
  BCard,
  BCardText,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BPopover,
    BButton,
    BFormGroup,
    BCardText,
    BFormInput,
    BCard,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      popoverShow: false,
      calculatorValue: "",
      calculatorElements: [
        "C",
        "*",
        "/",
        "-",
        7,
        8,
        9,
        "+",
        4,
        5,
        6,
        "%",
        1,
        2,
        3,
        "=",
        0,
        ".",
      ],
      operator: null,
      previousCalculatorValue: "",
    };
  },

  methods: {
    calculateResult(a, b, operator) {
      switch (operator) {
        case "+":
          return a + b;
        case "-":
          return a - b;
        case "*":
          return a * b;
        case "/":
          if (b !== 0) {
            return a / b;
          } else {
            return "Error"; // or some other error handling
          }
        default:
          return "Error"; // or some other error handling
      }
    },
    action(n) {
      /* Append value */
      if (!isNaN(n) || n === ".") {
        this.calculatorValue += n + "";
      }

      /* Clear value */
      if (n === "C") {
        this.calculatorValue = "";
      }

      /* Percentage */
      if (n === "%") {
        this.calculatorValue = this.calculatorValue / 100 + "";
      }

      /* Operators */
      if (["/", "*", "-", "+"].includes(n)) {
        this.operator = n;
        this.previousCalculatorValue = this.calculatorValue;
        this.calculatorValue = "";
      }
      if (n === "=") {
        this.calculatorValue = this.calculateResult(
          parseFloat(this.previousCalculatorValue),
          parseFloat(this.calculatorValue),
          this.operator
        );
        this.previousCalculatorValue = "";
        this.operator = null;
      }
    },
  },
};
</script>
